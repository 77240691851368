<template>
  <van-tabs
    v-model="activeTab"
    animated
    swipeable
    class="navigation-tab"
    @click="selectActiveTab"
  >
    <van-tab name="consumption">
      <template #title>
        {{ $t('reporting.tabs.title1', [diagnosisAbbreviation]) }}
      </template>
      <van-radio-group
        v-model="selectedTimeframe"
        class="food-list-filters"
        direction="horizontal"
        @change="handleChartChange"
      >
        <van-radio name="oneMonth">
          {{ $t('reporting.chart.oneMonth.label') }}
        </van-radio>
        <van-radio name="sixMonths">
          {{ $t('reporting.chart.sixMonths.label') }}
        </van-radio>
        <van-radio name="oneYear">
          {{ $t('reporting.chart.oneYear.label') }}
        </van-radio>
        <van-radio name="total">
          {{ $t('reporting.chart.total.label') }}
        </van-radio>
      </van-radio-group>
      <div class="container">
        <div class="title">
          <h3>{{ diagnosisAbbreviation }}-{{ chartTitle }}</h3>
          <p>({{ $t('reporting.chart.subtitle') }} {{ metabolismSpec }})</p>
        </div>
      </div>
      <div class="chart-container">
        <div class="chart-container__canvas">
          <canvas
            ref="chartCanvas"
          />
        </div>
        <div id="chart-legend-container" />
      </div>
      <div class="container">
        <data-table
          :title="tableTitle"
          :data="entries"
          :heading-left="$t('reporting.table.date')"
          :heading-right="`${diagnosisAbbreviation}-${chartTitle}`"
          class="chart-table"
        />
      </div>
    </van-tab>
    <!-- van-tab
      name="bloodValues"
    >
      <template #title>
        {{ $t('reporting.tabs.title2') }}
      </template>
    </van-tab -->
  </van-tabs>
</template>

<script>
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  parseISO,
  isWithinInterval,
  getMonth,
  getDate,
  format,
  isSameMonth,
  isSameDay,
  min,
  getYear,
  sub,
  lastDayOfMonth,
} from 'date-fns';
import { de } from 'date-fns/locale';
import { Chart, registerables } from 'chart.js';

import DataTable from '@/components/DataTable/DataTable.vue';
import { chartHtmlLegendPlugin, getNutritionLimits, getNutritionUnit } from '@/helper';

// https://github.com/sgratzl/chartjs-chart-wordcloud/issues/4#issuecomment-827304369
Chart.register(...registerables);

export default {
  name: 'ReportCharts',
  components: {
    DataTable,
  },
  props: {
    diagnosis: {
      type: Object,
      default: null,
    },
    diagnosisAbbreviation: {
      type: String,
      default: '',
    },
    metabolismSpec: {
      type: String,
      default: '',
    },
    selectedTimeframe: {
      type: String,
      default: 'sixMonths',
    },
    getSavedDayplans: {
      type: Function,
      default: () => [],
    },
    userProfile: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      activeTab: 'consumption',
      chartTitle: null,
      chart: null,
      chartData: {
        type: 'line',
        plugins: [chartHtmlLegendPlugin],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          lineTension: 0,
          interaction: {
            intersect: false,
          },
          layout: {
            padding: {
              right: 50,
            },
          },
          plugins: {
            htmlLegend: {
              containerID: 'chart-legend-container',
            },
            legend: {
              display: false,
            },
            tooltips: {
              position: 'nearest',
              callbacks: {
                label: tooltipItem => `${tooltipItem.yLabel}: ${tooltipItem.xLabel}`,
                title: () => null,
              },
            },
          },
          scales: {
            x: {
              grid: {
                borderColor: 'transparent',
                color: '#fff',
              },
              ticks: {
                font: {
                  size: 11,
                },
              },
            },
            y: {
              beginAtZero: true,
              grace: 20,
              grid: {
                display: false,
                borderColor: '#fff',
              },
              ticks: {
                count: 5,
                padding: 20,
                font: {
                  size: 11,
                },
              },
            },
          },
        },
      },
      datesInRange: [],
      chartLabels: [0, 0],
      tableTitle: this.$i18n.t('reporting.entries.title'),
      entries: [
        { label: 'loading...', value: '...' },
      ],
      savedDayplans: [],
      limitValues: [],
    };
  },

  created() {
    this.chartTitle = this.$i18n.t('reporting.chart.title', [getNutritionUnit(this.diagnosis.nutritionKey)]);
    this.tableTitle = this.$i18n.t(`reporting.chart.${this.selectedTimeframe}.tableTitle`);
    this.getDayplans();

    window.addEventListener('beforeprint', () => {
      this.chart.resize(700, 320);
    });

    window.addEventListener('afterprint', () => {
      this.chart.resize();
    });
  },

  destroyed() {
    window.removeEventListener('beforeprint', () => {
      this.chart.resize(700, 320);
    });

    window.removeEventListener('afterprint', () => {
      this.chart.resize();
    });
  },

  methods: {
    selectActiveTab(tab) {
      this.activeTab = tab;
    },

    async getDayplans() {
      const dates = await this.getSavedDayplans();

      if (Object.keys(dates).length) {
        dates.forEach(({ id, nutritionSums }) => {
          const nutrition = nutritionSums[this.diagnosis.nutritionKey];
          this.savedDayplans.push({ date: id, nutrition });
        });
      }

      if (this.selectedTimeframe) {
        this.renderMonthlyView();
      }
    },

    handleChartChange(chartType) {
      this.tableTitle = this.$i18n.t(`reporting.chart.${chartType}.tableTitle`);
      if (chartType === 'oneMonth') {
        this.renderDailyView();
      } else if (chartType === 'total') {
        this.renderTotalView();
      } else if (chartType === 'sixMonths' || chartType === 'oneYear') {
        this.renderMonthlyView();
      }
    },

    renderMonthlyView() {
      const today = new Date();
      const months = [];
      const chartValues = [];
      const chartLabels = [];
      const monthlyEntries = [];
      const duration = this.selectedTimeframe === 'sixMonths' ? 5 : 11;

      this.limitValues = [];

      for (let i = duration; i > 0; i -= 1) {
        const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
        months.push({ name: format(date, 'MMMM'), id: date.getMonth(), ...{ date } });
        monthlyEntries.push({ label: format(date, 'MMMM yyyy', { locale: de }), value: 0 });
        chartLabels.push(format(date, 'MMM', { locale: de }));
      }

      months.push({
        name: format(today, 'MMMM'),
        id: getMonth(today),
        date: today,
      });

      monthlyEntries.push({
        label: format(today, 'MMMM yyyy', { locale: de }),
        value: 0,
      });

      chartLabels.push(format(today, 'MMM', { locale: de }));

      // get saved dates in months range
      this.updateDatesRange(months[0].date, months[months.length - 1].date);

      for (let i = 0; i < months.length; i += 1) {
        const date = format(lastDayOfMonth(months[i]?.date), 'yyyy-MM-dd');
        const datelimits = getNutritionLimits(this.userProfile, date, false);
        const monthData = [];

        if (datelimits) {
          this.limitValues.push(datelimits[this.diagnosis.nutritionKey]);
        }

        this.savedDayplans.map((plan) => plan.nutrition
          && isSameMonth(parseISO(plan.date), months[i].date)
          && monthData.push(plan.nutrition));

        if (monthData.length) {
          chartValues[i] = this.getAverage(monthData);
          monthlyEntries[i].value = this.getAverage(monthData);
        } else {
          chartValues[i] = 0;
        }
      }

      const steppedValues = this.limitValues;

      this.entries = monthlyEntries.reverse();
      this.updateChart({ chartLabels, chartValues, steppedValues });
    },

    renderDailyView() {
      const today = new Date();
      const aMonthAgo = sub(today, { months: 1 });
      const lastMonthArray = eachDayOfInterval({ start: aMonthAgo, end: today });
      const chartValues = [];
      const chartEntries = [];
      const chartLabels = [];
      const dailyEntries = [];

      this.limitValues = [];

      lastMonthArray.map((day) => dailyEntries.push({
        label: format(day, 'dd.MM.yyyy'),
        value: 0,
        day,
      }));

      this.updateDatesRange(aMonthAgo, today);

      for (let i = 0; i < dailyEntries.length; i += 1) {
        this.datesInRange.map((data) => isSameDay(dailyEntries[i].day, parseISO(data.date))
          && data.nutrition
          && (dailyEntries[i].value = data.nutrition));

        const formattedDay = getDate(dailyEntries[i].day).toString().padStart(2, '0');
        const formattedMonth = (getMonth(dailyEntries[i].day) + 1).toString().padStart(2, '0');
        const formattedYear = getYear(dailyEntries[i].day);

        chartEntries.push(`${formattedYear}-${formattedMonth}-${formattedDay}`);
        chartLabels.push(`${formattedDay}.${formattedMonth}`);
      }

      for (let i = 0; i < dailyEntries.length; i += 1) {
        const date = format(dailyEntries[i]?.day, 'yyyy-MM-dd');
        const datelimits = getNutritionLimits(this.userProfile, date, false);

        this.limitValues.push(datelimits[this.diagnosis.nutritionKey]);

        chartValues[i] = dailyEntries[i].value && dailyEntries[i].value !== undefined
          ? dailyEntries[i].value
          : 0;
      }

      const steppedValues = this.limitValues;

      this.entries = dailyEntries.reverse();
      this.updateChart({ chartLabels, chartValues, steppedValues });
    },

    renderTotalView() {
      const today = new Date();
      const dates = [];
      const chartValues = [];
      const chartEntries = [];
      const chartLabels = [];
      const allEntries = [];
      let sameMonthData = [];

      this.limitValues = [];

      this.savedDayplans.map((plan) => {
        dates.push(parseISO(plan.date));
        return false;
      });

      const minimimDate = min(dates);
      const intervalArray = eachMonthOfInterval({
        start: minimimDate,
        end: today,
      });

      for (let i = 0; i < intervalArray.length; i += 1) {
        const date = format(lastDayOfMonth(intervalArray[i]), 'yyyy-MM-dd');
        const datelimits = getNutritionLimits(this.userProfile, date, false);

        this.limitValues.push(datelimits[this.diagnosis.nutritionKey]);

        // for every iteration, saved data in the same months are saved in sameMonthDate
        // then the average value is then pushed to index "i" in internalValues
        sameMonthData = [];
        chartEntries.push(getMonth(intervalArray[i]));
        chartLabels.push(`${format(intervalArray[i], 'LLL', { locale: de })} ${format(intervalArray[i], 'yy')}`);

        // All entries is initialized with zero here, before replacing its corresponding
        // month average value L288
        allEntries.push({
          label: `${format(intervalArray[i], 'LLLL', { locale: de })} ${getYear(intervalArray[i])}`,
          value: 0,
        });

        // push saved nutritions to "sameMonthData", if its in the same month as current
        // month in evaluation intervalArr[i]
        for (let j = 0; j < this.savedDayplans.length; j += 1) {
          if (
            isSameMonth(intervalArray[i], parseISO(this.savedDayplans[j].date))
            && this.savedDayplans[j].nutrition
          ) {
            sameMonthData.push(this.savedDayplans[j].nutrition);
          }
        }

        if (sameMonthData.length > 0) {
          const average = this.getAverage(sameMonthData);
          chartValues.push(average);
          allEntries[i].value = average;
        } else {
          chartValues.push(0);
          allEntries[i].value = 0;
        }
      }

      const steppedValues = this.limitValues;

      this.entries = allEntries.reverse();
      this.updateChart({ chartLabels, chartValues, steppedValues });
    },

    updateChart({ chartLabels, chartValues, steppedValues }) {
      const ctx = this.$refs.chartCanvas.getContext('2d');

      const data = {
        ...this.chartData,
        data: {
          labels: chartLabels,
          datasets: [
            {
              label: this.chartTitle,
              data: chartValues,
              backgroundColor: '#f0f0f0',
              borderColor: '#6d49b3',
              borderWidth: 2,
            },
            {
              label: this.$i18n.t('values.requirements.diagnosis', [
                this.$i18n.t(`nutritions.${this.diagnosis.nutritionKey}`),
              ]),
              data: steppedValues,
              backgroundColor: '#f0f0f0',
              borderColor: '#7fa634',
              borderWidth: 2,
              borderDash: [5, 2],
              stepped: true,
              pointStyle: 'rect',
              // pointRadius: 0, /* Hides the dots */
            },
          ],
        },
      };

      if (this.chart) this.chart.destroy();
      // eslint-disable-next-line no-new
      this.chart = new Chart(ctx, data);
    },

    checkRange(date, start, end) {
      return isWithinInterval(parseISO(date), { start, end });
    },

    chunkArrayIntoGroups(array, size) {
      const chunked = [];
      for (let i = 0; i < array.length; i += size) {
        chunked.push(array.slice(i, i + size));
      }
      return chunked;
    },

    updateDatesRange(start, end) {
      this.datesInRange = [];
      this.savedDayplans.forEach((savedPlan) => {
        if (this.checkRange(savedPlan.date, start, end)) {
          this.datesInRange.push(savedPlan);
        }
      });
    },

    getAverage(values) {
      const reducer = values.reduce((acc, curr) => acc + curr, 0);
      if (typeof reducer === 'number') {
        return (reducer / values.length).toFixed(0);
      }
      return 0;
    },
  },
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;

.title {
  margin: config.$spacing-lg 0;

  h3 {
    font-size: config.$font-size;
    line-height: 22px;
    margin: 0;
  }

  p {
    font-size: 14px;
    color: config.$color-darker;
    margin: 0;
  }

  @media print {
    margin: 0 0 4px;
  }
}

.navigation-tab .van-tabs__wrap {
  margin: config.$spacing;
  .van-tabs__line {
    z-index: 0;
  }
}

.chart-summary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: config.$spacing;
  margin: config.$spacing-xs 0 config.$spacing;
  text-align: center;

  .indicator__title {
    margin-bottom: 0.3em;
  }
}

.chart-navigation {
  box-shadow: none;
  z-index: 0;

  &:after {
    display: none;
  }

  .van-nav-bar__left .van-icon {
    margin-right: config.$spacing-xs;
  }

  .van-nav-bar__right .van-icon {
    transform: rotate(180deg);
    margin-left: config.$spacing-xs;
  }
}

.food-list-filters {
  padding: 0 config.$spacing;
  margin: config.$spacing 0 #{config.$spacing * 0.5};
  font-size: 0.75rem;

  .van-radio__icon {
    display: none;
  }

  .van-radio__label {
    display: block;
    padding: config.$spacing-xxs config.$spacing-xs;
    border: 2px transparent solid;
    border-radius: 999px;
    margin-left: 0;
    line-height: 1;
    background-color: config.$color-light;
  }

  [aria-checked="true"] .van-radio__label {
    border-color: config.$brand-secondary;
    background-color: rgba(config.$brand-secondary, 0.2);
  }

  @media print {
    display: none;
  }
}

.chart-table {
  margin: config.$spacing 0;

  .data-table__row {
    padding: 16px 0;
    border-bottom: 1px solid #e7e7e7;
    border-top: 0;

    &:last-child {
      border-bottom: none;
    }

    @media print {
      padding: 4px 0;
    }
  }

  .data-table__title {
    margin-bottom: 20px;

    @media print {
      margin-bottom: 4px;
    }
  }
}

.chart-legend {
  $parent: &;

  background: config.$color-light;
  display: flex;
  flex-direction: row;
  padding: config.$spacing config.$spacing-sm config.$spacing-xs config.$spacing-xxl;

  &__item {
    font-size: config.$font-size-xxs;
    font-weight: 600;
    padding: config.$spacing-xxs;
    position: relative;

    &.is-hidden {
      opacity: 0.5;

      #{$parent}__marker {
        background: config.$color-mid-light !important;
      }
    }
  }

  &__marker {
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

// https://www.chartjs.org/docs/3.9.1/configuration/responsive.html#important-note
.chart-container {
  max-width: 100%;
  position: relative;
  height: auto;
  width: 100vw;

  @media (min-width: 1024px) {
    padding: 0 20px;
    width: 100%;
  }

  &__canvas {
    canvas {
      background: config.$color-light;
      min-height: 300px;
    }
  }
}
</style>
