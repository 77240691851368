import { auth, db, functions } from '@/firebase';

export default class UserReport {
  reportCollection = 'sharedReports';

  constructor(router) {
    this.router = router;
    this.reference = db.collection(this.reportCollection);
    this.authUserId = auth?.currentUser?.uid || null;
    this.authUserEmail = auth?.currentUser?.email || null;
  }

  isUserLoggedIn() {
    return !!this.authUserId;
  }

  async getUserProfile(uid) {
    const functionsGetUserProfile = functions.httpsCallable('getUserProfile');
    const result = await functionsGetUserProfile({ uid });
    return result.data;
  }

  async getSavedDayplans(uid) {
    const functionsGetSavedDayplans = functions.httpsCallable('getSavedDayplans');
    const result = await functionsGetSavedDayplans({ uid });
    return result.data;
  }

  async getSavedToken(email = this.authUserEmail) {
    const functionsGetSavedToken = functions.httpsCallable('getSavedToken');
    const result = await functionsGetSavedToken({ email });
    return result.data;
  }

  async updateSavedToken(key = this.authUserEmail) {
    const userData = {
      uid: this.authUserId,
      email: this.authUserEmail,
    };

    const functionsCreateToken = functions.httpsCallable('createToken');
    const result = await functionsCreateToken(userData);
    const token = result.data;
    await this.reference.doc(key).set({ token });
    return token;
  }

  async getToken() {
    let token = await this.getSavedToken();

    if (!token) {
      token = await this.updateSavedToken();
    }

    return token;
  }

  async validateToken() {
    const { token } = this.router.history?.current?.params;

    if (!token) {
      return false;
    }

    try {
      const functionsGetToken = functions.httpsCallable('getToken');
      const result = await functionsGetToken({ token });
      const userData = result.data;

      const currentToken = await this.getSavedToken(userData.email);
      return userData && token && token === currentToken ? userData : false;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  getSharedLink(token) {
    const { protocol, host } = window.location;
    const sharedRoute = this.router.resolve({
      name: 'sharedReport',
      params: {
        token,
      },
    });

    return `${protocol}//${host}${sharedRoute.href}`;
  }

  async getLink() {
    const token = await this.getToken();
    const sharedLink = this.getSharedLink(token);

    return sharedLink;
  }

  // eslint-disable-next-line
  copyLink(link) {
    const textArea = document.createElement('textarea');
    textArea.value = link;
    textArea.style.position = 'absolute';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    textArea.style.opacity = 0;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    textArea.setSelectionRange(0, 99999);

    document.execCommand('copy');
    textArea.remove();

    return link;
  }

  async renewLink() {
    const token = await this.updateSavedToken();
    const sharedLink = this.getSharedLink(token);

    return sharedLink;
  }
}
