<template>
  <div class="data-table">
    <p
      v-if="title"
      class="data-table__title"
    >
      {{ title }}
    </p>
    <div
      v-if="headingLeft || headingRight"
      class="data-table__row space-between"
    >
      <span class="data-table__heading">{{ headingLeft }}</span>
      <span class="data-table__heading">{{ headingRight }}</span>
    </div>
    <div v-if="data && data.length > 0">
      <div
        v-for="item in data"
        :key="item.label"
        class="data-table__row"
      >
        <span class="data-table__label">{{ item.label }}</span>
        <span class="data-table__value">{{ item.value }}</span>
      </div>
    </div>
    <slot v-else />
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    headingLeft: { type: String, default: '' },
    headingRight: { type: String, default: '' },
    data: { type: Array, default: () => [] },
  },
};
</script>

<style lang="scss">
@use '~@/styles/config' as config;

.data-table__title {
  margin: 0;
  font-size: config.$font-size-md;
  font-weight: bold;
  color: config.$color-darker;
}

.data-table__row {
  display: flex;
  padding: 0.4375rem 0;
  gap: config.$spacing;
  color: config.$color-darker;
  &.space-between {
    justify-content: space-between;
  }

  & + & {
    border-top: 1px solid #ccc;
  }
}

.data-table__label {
  margin-right: auto;
}

.data-table__heading {
  font-weight: 600;
  font-size: config.$font-size-md;
}

.data-table__value {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
